export * from './prisma/client';
import { PrismaClient } from './prisma/client';

declare global {
  var prisma: PrismaClient | undefined;
}

export const prismadb = globalThis.prisma || new PrismaClient();
if (process.env.NODE_ENV !== 'production') globalThis.prisma = prismadb;

// @ts-ignore
BigInt.prototype.toJSON = function (): string {
  return this.toString();
};
